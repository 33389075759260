<template>
  <div
    v-if="optionType === enums.skuOptionTypeEnum.IMAGE.value"
    class="attributeItemView"
  >
    <div v-if="showLabel" class="itemName fs-16 fw-400 ff-ss">
      <slot name="optionName">
        {{ labelPrefix }} {{ optionName }} <span style="color: red;">*</span>
      </slot>
    </div>
    <div class="attributeSelectHasImg">
      <a-select
        class="current-selection--single w-100"
        show-search
        :value="selectValue || undefined"
        :allow-clear="true"
        :placeholder="`${placeHolderPrefix} ${optionName}`"
        @change="handleSelectChange"
      >
        <a-select-option
          v-for="item in optionValues"
          :key="item.text"
          :disabled="item.skuIds | isDisabledOption(validSkuIds)"
          :value="item.text"
        >
          <div class="d-flex a-center">
            <img class="optionImg" :src="item.fileUrl">
            <span class="pl5">{{ item.text }}</span>
          </div>
        </a-select-option>
      </a-select>
    </div>
  </div>
  <div v-else class="attributeItemView">
    <div v-if="showLabel" class="itemName fs-16 fw-400 ff-ss">
      <slot name="optionName">
        {{ labelPrefix }} {{ optionName }} <span style="color: red;">*</span>
      </slot>
    </div>
    <div class="attributeSelect">
      <a-select
        class="current-selection--single"
        show-search
        :allow-clear="true"
        :value="selectValue || undefined"
        :placeholder="`${placeHolderPrefix} ${optionName}`"
        style="width: 100%"
        @change="handleSelectChange"
      >
        <a-select-option
          v-for="item in optionValues"
          :key="item.text"
          :disabled="item.skuIds | isDisabledOption(validSkuIds)"
          :value="item.text"
        >
          <span
            v-if="optionType === enums.skuOptionTypeEnum.COLOR.value"
            class="mr10 colorIcon"
            :style="{backgroundColor: item.color}"
          />
          <span>
            {{ item.text }}
          </span>
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import skuOptionTypeEnum from '@/enums/blank_product/skuOptionTypeEnum'

export default {
  name: 'SkuOptionSelector',
  filters: {
    isDisabledOption(optionSkuIds, validSkuIds) {
      console.log('optionSkuIds', optionSkuIds)
      console.log('validSkuIds', validSkuIds)
      if (!validSkuIds || validSkuIds.length === 0) {
        return false
      }
      for (const validSkuId of validSkuIds) {
        if (optionSkuIds.indexOf(validSkuId) >= 0) {
          return false
        }
      }
      return true
    }
  },
  props: {
    optionValues: {
      type: Array,
      default: () => []
    },
    placeHolderPrefix: {
      type: String,
      default: 'Please select'
    },
    labelPrefix: {
      type: String,
      default: 'Select'
    },
    optionType: {
      type: Number,
      default: () => skuOptionTypeEnum.GENERAL.value
    },
    optionName: {
      type: String,
      default: ''
    },
    selectValue: {
      type: String,
      default: null
    },
    selectSkuIds: {
      type: Array,
      default: () => []
    },
    validSkuIds: {
      type: Array,
      default: () => []
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      enums: {
        skuOptionTypeEnum
      },
      optionDisabled: {}
    }
  },
  watch: {
    validSkuIds() {
      if (!this.selectValue) {
        return
      }
      console.log('validSkuIds change', this.validSkuIds)
      if (this.validSkuIds && this.validSkuIds.length > 0) {
        let isValid = false
        for (const validSkuId of this.validSkuIds) {
          if (this.selectSkuIds.indexOf(validSkuId) >= 0) {
            isValid = true
            break
          }
        }
        if (!isValid) {
          this.handleSelectChange(null)
        }
      }
    }
  },
  methods: {
    select(v) {
      this.handleSelectChange(v)
    },
    selectFirst() {
      if (this.optionValues && this.optionValues.length) {
        this.handleSelectChange(this.optionValues[0].text)
      }
    },
    handleSelectChange(v) {
      console.log('handleSelectChange', v)
      if (!v) {
        this.$emit('update:selectValue', null)
        this.$emit('update:selectSkuIds', [])
        this.$emit('selectSkuIdsChange', [])
      } else {
        this.$emit('update:selectValue', v)
        for (const optionValue of this.optionValues) {
          if (optionValue.text === v) {
            this.$emit('update:selectSkuIds', optionValue.skuIds)
            this.$emit('selectSkuIdsChange', optionValue.skuIds)
            break
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .optionImg{
    width: 30px;
    height: 30px;
  }
.current-selection--single /deep/ .ant-select-selection--single{
  height: 39px !important;
  line-height: 39px !important;
  background-color: #ffffff !important;
  color: #5e6278;
  border: 1px solid #e4e6ef !important;
  border-radius: 8px;
}
.ant-select-open /deep/ .ant-select-selection--single {
  border: 1px solid #b5b5c3 !important;
}
.attributeItemView {
  width: 100%;
  .itemName {
    padding-top: 40px;
    padding-bottom: 14px;
  }
  .attributeSelectHasImg {
    /deep/ .el-input__prefix {
      display: flex;
      align-items: center;
    }
    /deep/ .el-input__inner {
      padding-left: 2.5vw;
    }
  }
  .attributeSelect {
    .colorIcon{
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }
}
</style>
