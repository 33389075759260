import logisticsMethodTypeEnum from '@/enums/logistics_method/logisticsMethodTypeEnum'

export function getDefaultLogisticsMethodConfig(logisticsMethodInfo) {
  for (const configInfo of logisticsMethodInfo.items) {
    for (const country of configInfo.countryItems) {
      if (country.countryCode.toLowerCase() === 'US'.toLowerCase()) {
        return configInfo
      }
    }
  }
  return logisticsMethodInfo.items[0]
}

export function getLogisticsMethodConfig(logisticsMethodInfo, countryCode) {
  for (const configInfo of logisticsMethodInfo.items) {
    for (const country of configInfo.countryItems) {
      if (country.countryCode.toLowerCase() === countryCode.toLowerCase()) {
        return configInfo
      }
    }
  }
  return null
}

export function getLogisticsMethodConfigByCountryGroup(logisticsMethodInfo, countryGroupId) {
  for (const configInfo of logisticsMethodInfo.items) {
    if (configInfo.countryGroupId === countryGroupId) {
      return configInfo
    }
  }
  return null
}

// 计算物流方式价格
// weight: 重量，单位kg
// logisticsMethodConfig: 物流方式配置
export function calculateLogisticsMethodPrice(weight = 0, logisticsMethodConfig, shippingData) {
  if (shippingData.type === logisticsMethodTypeEnum.COMBINATION.value) {
    const priceItem = getPriceItem(weight, logisticsMethodConfig)
    if (!priceItem) {
      return null
    }
    // 组合方式，计算价格
    var fee = (priceItem.pricePerKilo * weight)
    var registrationFee = priceItem.registrationFee
    return fee + registrationFee
  } else if (shippingData.type === logisticsMethodTypeEnum.MAGNIFICATION.value) {
    // 倍率方式，计算价格
    const weightM = Math.ceil(weight / logisticsMethodConfig.minUnitWeight)
    const weightC = weightM * logisticsMethodConfig.minUnitWeight
    // 倍率方式，安装重新计算的重量后去配置信息
    const priceItem = getPriceItem(weightC, logisticsMethodConfig)
    if (!priceItem) {
      return null
    }
    return weightC * priceItem.magnification * logisticsMethodConfig.minUnitWeightPrice
  } else if (shippingData.type === logisticsMethodTypeEnum.MAGNIFICATION2.value) {
    // 倍率方式2，计算价格
    const weightM = Math.ceil(weight / 0.5)
    const weightM2 = weightM - 1
    return logisticsMethodConfig.firstHalfKgPrice + weightM2 * logisticsMethodConfig.moreHalfKgPrice
  } else {
    console.error('not support logistics method type')
    return null
  }
}

// 获取价格配置
// weight: 重量,单位key
// logisticsMethodConfig: 物流方式配置
export function getPriceItem(weight, logisticsMethodConfig) {
  for (const priceItem of logisticsMethodConfig.priceItems) {
    if (priceItem.weightMin <= weight && priceItem.weightMax > weight) {
      return priceItem
    }
  }
  return null
}
