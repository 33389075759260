<template>
  <div class="reviewsView" :class="showMarginBottom ? '' : 'pb_60'">
    <div class="reviewsTitle">Reviews</div>
    <reviews-content
      v-show="haveComments && !isWriteReview"
      :comments="comments"
      :star1-num="star1Num"
      :star2-num="star2Num"
      :star3-num="star3Num"
      :star4-num="star4Num"
      :star5-num="star5Num"
      :total="pageInfo.total"
      :product-id="productId"
      @writereview="toWriteReview"
      @pagechange="toPageChange"
    />
    <empty-reviews v-show="!haveComments && !isWriteReview" @writereview="toWriteReview" />
    <write-reviews v-if="isWriteReview" :product-id="productId" @done="doneWriteReview" />
  </div>
</template>

<script>
import ReviewsContent from './ReviewsContent'
import EmptyReviews from './EmptyReviews'
import WriteReviews from './WriteReviews'
import { commentList } from '@/api/comment'
import { mapGetters } from 'vuex'
export default {
  name: 'CommentList',
  components: {
    ReviewsContent,
    EmptyReviews,
    WriteReviews
  },
  props: {
    productId: {
      type: String,
      required: true
    },
    showMarginBottom: {
      type: Boolean
    }
  },
  data() {
    return {
      comments: [],
      star1Num: 0,
      star2Num: 0,
      star3Num: 0,
      star4Num: 0,
      star5Num: 0,
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      isWriteReview: false
    }
  },
  computed: {
    ...mapGetters('User', {
      isLogin: 'getIsLogin'
    }),
    starAverage() {
      return 0
    },
    haveComments() {
      return this.comments && this.comments.length
    }
  },
  mounted() {
    this.loadCommentList()
  },
  methods: {
    loadCommentList() {
      var postData = {}
      postData.productId = this.productId
      postData.current = this.pageInfo.currentPage
      postData.size = this.pageInfo.pageSize
      commentList(postData).then(response => {
        var data = response.data
        this.comments = data.records || []
        this.star1Num = data.star1Num || 0
        this.star2Num = data.star2Num || 0
        this.star3Num = data.star3Num || 0
        this.star4Num = data.star4Num || 0
        this.star5Num = data.star5Num || 0
        this.pageInfo.total = data.total || 0
      })
    },
    toWriteReview() {
      if (!this.isLogin) {
        this.callNewPage({
          path: `/login`,
          query: {
            redirect: this.$route.path
          }
        })
        return
      }
      this.isWriteReview = true
    },
    toPageChange({ pageSize, pageIndex }) {
      this.pageInfo.pageSize = pageSize
      this.pageInfo.currentPage = pageIndex
      this.loadCommentList()
    },
    doneWriteReview() {
      this.isWriteReview = false
      this.loadCommentList()
    }
  }
}
</script>

<style lang="less" scoped>
.pb_60{
  padding-bottom: 60px;
}
.reviewsView{
  .reviewsTitle{
    padding-top: 60px;
    padding-bottom: 24px;
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #333333;
  }
}
</style>
