<template>
  <div>
    <a-modal
      class="sizeDetailContainer"
      :visible.sync="visible"
      width="35%"
      centered
      @cancel="handleClose"
    >
      <div class="sizeDetailTitle">Size Detail</div>
      <a-tabs v-model="activeName">
        <a-tab-pane key="mertics" tab="mertics">
          <!-- <div class="pl20 pr20" v-html="metric" /> -->
           <div class="pl20 pr20">
            <a-table
            :columns="columns"
            :data-source="dataMetirc"
            :pagination="false"
            bordered
              >
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="lmperail" tab="Lmperail">
          <!-- <div class="pl20 pr20" v-html="imperial" /> -->
          <div class="pl20 pr20">
            <a-table
            :columns="columnsLmperail"
            :data-source="dataMetirc"
            :pagination="false"
            bordered
              >
            </a-table>
          </div>
        </a-tab-pane>
      </a-tabs>

      <span slot="footer" class="dialog-footer">
        <a-button @click="handleClose">cancel</a-button>
        <a-button type="primary" @click="handleClose">confirm</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Size Chart (metirc)',
    children: [
      {
        title: 'US',
        children: [
          {
            title: 'Men',
            dataIndex: 'usMenSize',
            key: 'usMenSize'
          },
          {
            title: 'Women',
            dataIndex: 'usWomenSize',
            key: 'usWomenSize'
          }
        ]
      },
      {
        title: 'EU',
        children: [
          {
            title: ' ',
            dataIndex: 'euSize',
            key: 'euSize'
          }
        ]
      }
    ]
  }
]
export default {
  name: 'SizeDetailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    metric: {
      type: String,
      default: ''
    },
    imperial: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeName: 'mertics',
      columns,
      columnsLmperail: [{ ...columns[0], title: 'Size Chart (lmperail)' }],
      dataMetirc: [{
        key: 11,
        usMenSize: '-',
        usWomenSize: '5.5',
        euSize: '36'
      }, {
        key: 12,
        usMenSize: '-',
        usWomenSize: '6',
        euSize: '37'
      }, {
        key: 13,
        usMenSize: '5',
        usWomenSize: '7',
        euSize: '38'
      }, {
        key: 14,
        usMenSize: '6',
        usWomenSize: '8',
        euSize: '39'
      }, {
        key: 15,
        usMenSize: '7',
        usWomenSize: '9',
        euSize: '40'
      }, {
        key: 16,
        usMenSize: '7.5',
        usWomenSize: '10',
        euSize: '41'
      }, {
        key: 17,
        usMenSize: '8.5',
        usWomenSize: '11',
        euSize: '42'
      }, {
        key: 18,
        usMenSize: '9.5',
        usWomenSize: '11.5',
        euSize: '43'
      }, {
        key: 19,
        usMenSize: '10',
        usWomenSize: '12',
        euSize: '44'
      }, {
        key: 20,
        usMenSize: '11',
        usWomenSize: '-',
        euSize: '45'
      }, {
        key: 21,
        usMenSize: '12',
        usWomenSize: '-',
        euSize: '46'
      }]
    }
  },
  created: function() {

  },
  mounted: function() {

  },
  methods: {
    handleClose() {
      this.$emit('update:visible', !this.visible)
    }

  }
}
</script>

<style lang="less" scoped>
 @import '../../../assets/less/theme.less';
  .sizeDetailContainer{
    .sizeDetailTitle{
      font-size: 16px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      text-align: center;
      padding-top: 30px;
      // border-bottom: 1px dashed #eff2f5 !important;
      padding-bottom: 24px;
    }
    /deep/ .ant-modal-body{
      height: 550px;
      overflow-y: auto;
      .commonBar(#d2d2d8, 8px, 8px);
    }
    /deep/ .ant-table-thead > tr > th{
      background-color: transparent;
      border-bottom: 1px dashed #999999;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #999999;
    }
    /deep/ .ant-table-tbody > tr > td{
      background-color: transparent !important;
      border-bottom: 1px dashed #999999 !important;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #000000;
    }
    /deep/ .ant-table-wrapper {
      border-bottom: none;
    }
    /deep/ .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td{
      border-right: 1px dashed #999999;
    }
    /deep/ .ant-table-bordered .ant-table-body > table{
      border: 1px dashed #999999 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
    }
    /deep/ .ant-table-bordered .ant-table-tbody > tr > td{
      border-right: 1px dashed #999999;
    }
    /deep/ .ant-table-tbody > tr > td{
      border-color: #999999 !important;
    }
  }
</style>
