<template>
  <div :class="className">
    <sku-option-selector
      v-if="option1Type && option1Name && option1Values"
      ref="optino1Selector"
      :place-holder-prefix="placeHolderPrefix"
      :label-prefix="labelPrefix"
      :show-label="showLabel"
      :option-name="option1Name"
      :option-type="option1Type"
      :option-values="option1Values"
      :select-value.sync="selectOption1Value"
      :select-sku-ids.sync="selectOption1SkuIds"
      :valid-sku-ids="selectOption2SkuIds.concat(selectOption3SkuIds)"
    >
      <template v-slot:optionName>
        <slot :optionName="option1Name" :optionType="option1Type" :index="1" name="optionName" />
      </template>
    </sku-option-selector>
    <sku-option-selector
      v-if="option2Type && option2Name && option2Values"
      ref="optino2Selector"
      :place-holder-prefix="placeHolderPrefix"
      :label-prefix="labelPrefix"
      :show-label="showLabel"
      :option-name="option2Name"
      :option-type="option2Type"
      :option-values="option2Values"
      :select-value.sync="selectOption2Value"
      :select-sku-ids.sync="selectOption2SkuIds"
      :valid-sku-ids="selectOption1SkuIds.concat(selectOption3SkuIds)"
    >
      <template v-slot:optionName>
        <slot :optionName="option2Name" :optionType="option2Type" :index="2" name="optionName" />
      </template>
    </sku-option-selector>
    <sku-option-selector
      v-if="option3Type && option3Name && option3Values"
      ref="optino3Selector"
      :place-holder-prefix="placeHolderPrefix"
      :label-prefix="labelPrefix"
      :show-label="showLabel"
      :option-name="option3Name"
      :option-type="option3Type"
      :option-values="option3Values"
      :select-value.sync="selectOption3Value"
      :select-sku-ids.sync="selectOption3SkuIds"
      :valid-sku-ids="selectOption1SkuIds.concat(selectOption2SkuIds)"
    >
      <template v-slot:optionName>
        <slot :optionName="option3Name" :optionType="option3Type" :index="3" name="optionName" />
      </template>
    </sku-option-selector>
  </div>
</template>

<script>
import skuOptionTypeEnum from '@/enums/blank_product/skuOptionTypeEnum'
import SkuOptionSelector from './sku_option_selector'
import util from '@/utils/util'
var getValueByText = function(values, text) {
  for (const value of values) {
    if (value.text === text) {
      return value
    }
  }
  return null
}

export default {
  name: 'SkuSelector',
  components: {
    SkuOptionSelector
  },
  props: {
    skus: {
      type: Array,
      default: () => []
    },
    option1Type: {
      type: Number,
      default: skuOptionTypeEnum.GENERAL.value
    },
    option2Type: {
      type: Number,
      default: skuOptionTypeEnum.GENERAL.value
    },
    option3Type: {
      type: Number,
      default: skuOptionTypeEnum.GENERAL.value
    },
    option1Name: {
      type: String,
      default: ''
    },
    option2Name: {
      type: String,
      default: ''
    },
    option3Name: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: 'attributesView'
    },
    placeHolderPrefix: {
      type: String,
      default: 'Please select'
    },
    labelPrefix: {
      type: String,
      default: 'Select'
    },
    showLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectOption1Value: null,
      selectOption2Value: null,
      selectOption3Value: null,
      selectOption1SkuIds: [],
      selectOption2SkuIds: [],
      selectOption3SkuIds: [],
      enums: {
        skuOptionTypeEnum
      }
    }
  },
  computed: {
    option1Values() {
      if (!this.skus) {
        return []
      }
      var values = []
      for (const sku of this.skus) {
        if (sku.option1) {
          var option1 = JSON.parse(sku.option1)
          if (!option1.text) {
            continue
          }
          var option = getValueByText(values, option1.text)
          if (!option) {
            option = Object.assign({}, option1)
            option.skuIds = []
            option.skuIds.push(sku.id)
            values.push(option)
          } else {
            option.skuIds.push(sku.id)
          }
        }
      }
      return values
    },
    option2Values() {
      if (!this.skus) {
        return []
      }
      var values = []
      for (const sku of this.skus) {
        if (sku.option2) {
          var option2 = JSON.parse(sku.option2)
          if (!option2.text) {
            continue
          }
          var option = getValueByText(values, option2.text)
          if (!option) {
            option = Object.assign({}, option2)
            option.skuIds = []
            option.skuIds.push(sku.id)
            values.push(option)
          } else {
            option.skuIds.push(sku.id)
          }
        }
      }
      return values
    },
    option3Values() {
      if (!this.skus) {
        return []
      }
      var values = []
      for (const sku of this.skus) {
        if (sku.option3) {
          var option3 = JSON.parse(sku.option3)
          if (!option3.text) {
            continue
          }
          var option = getValueByText(values, option3.text)
          if (!option) {
            option = Object.assign({}, option3)
            option.skuIds = []
            option.skuIds.push(sku.id)
            values.push(option)
          } else {
            option.skuIds.push(sku.id)
          }
        }
      }
      return values
    },
    selectSkuId() {
      if (!this.skus) {
        return null
      }

      for (const sku of this.skus) {
        if (sku.option1) {
          var option1 = JSON.parse(sku.option1)
          if (option1.text) {
            if (option1.text !== this.selectOption1Value) {
              continue
            }
          }
        }
        if (sku.option2) {
          var option2 = JSON.parse(sku.option2)
          if (option2.text) {
            if (option2.text !== this.selectOption2Value) {
              continue
            }
          }
        }
        if (sku.option3) {
          var option3 = JSON.parse(sku.option3)
          if (option3.text) {
            if (option3.text !== this.selectOption3Value) {
              continue
            }
          }
        }
        return sku.id
      }
      return null
    }
  },
  watch: {
    selectSkuId() {
      console.log('sku Change', this.selectSkuId)
      this.$emit('skuChange', this.selectSkuId)
    }
  },
  methods: {
    getSkuId() {
      return this.selectSkuId
    },
    async selectFirst() {
      var sku = this.skus[0]
      var that = this
      if (this.option1Type && this.option1Name && this.option1Values) {
        await util.waitForEle(() => { return that.$refs.optino1Selector })
        this.selectOption1Value = JSON.parse(sku.option1).text
        // this.$refs.optino1Selector.select(JSON.parse(sku.option1).text)
      }
      if (this.option2Type && this.option2Name && this.option2Values) {
        await util.waitForEle(() => { return that.$refs.optino2Selector })
        this.selectOption2Value = JSON.parse(sku.option2).text
        // this.$refs.optino2Selector.select(JSON.parse(sku.option2).text)
      }
      if (this.option3Type && this.option3Name && this.option3Values) {
        await util.waitForEle(() => { return that.$refs.optino3Selector })
        this.selectOption3Value = JSON.parse(sku.option3).text
        // this.$refs.optino3Selector.select(JSON.parse(sku.option3).text)
      }
    }

  }
}
</script>

<style lang="less" scoped>
.attributesView {
  padding: 24px;
  // width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  margin-top: 40px;
}
.flex{
  display: flex;
}
</style>
