<template>
  <div>
    <a-modal
      class="shippingPriceContainer"
      :visible.sync="visible"
      width="60%"
      centered
      @cancel="handleClose"
    >
      <div class="shippingPriceTitle">Shipping fees</div>
      <div class="a-center ">
        <sku-selector-plain
          ref="skuSelector"
          style="margin-bottom: 20px"
          label-prefix=""
          class-name="flex"
          :skus="skuList"
          :show-label="false"
          :option1-name="blankProductInfo.skuOption1"
          :option1-type="blankProductInfo.skuOption1Type"
          :option2-name="blankProductInfo.skuOption2"
          :option2-type="blankProductInfo.skuOption2Type"
          :option3-name="blankProductInfo.skuOption3"
          :option3-type="blankProductInfo.skuOption3Type"
          @skuChange="onSkuChange"
        />
        <span class="pl20">Quantity：</span>
        <a-input-number v-model.number="quantityValue" class="quantityInput" :min="1" :max="100000" />
        <!-- <span class="pl20">Weight：{{ totalWeightStr }}</span> -->
      </div>
      <div class="pb10 pt10 color-red" style="color: #f34547">Note:A Remote Area Surcharge is applied per shipment when the  international delivery destination or pick up location is remote. </div>
      <a-table
        :columns="columns"
        :data-source="calculateShippingDatas"
        :pagination="false"
      >
        <span slot="name" slot-scope="text">{{ text || '/' }}</span>
      </a-table>
      <span slot="footer" class="dialog-footer">
        <a-button @click="handleClose">cancel</a-button>
        <a-button type="primary" @click="handleClose">confirm</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script >
import { getLogisticsMethodConfigByCountryGroup, calculateLogisticsMethodPrice } from '../common'
import { MoneyFormat } from '@/filters'
import SkuSelectorPlain from '@/components/sku_selector_plain'
import utils from '@/utils/util'
import { nanoid } from 'nanoid'
export default {
  name: 'ShippingPriceDialog',
  components: {
    SkuSelectorPlain
  },
  props: {
    skuList: {
      type: Array,
      default: () => []
    },
    countryGroups: {
      type: Array,
      default: () => []
    },
    shippingDatas: {
      type: Array,
      default: () => []
    },
    blankProductInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      quantityValue: 1,
      selectSkuId: null,
      visible: false
    }
  },
  computed: {
    columns() {
      var rs = []
      rs.push({
        title: 'Shipping to',
        dataIndex: 'shippingTo',
        key: 'shippingTo'
      })
      for (const shippingData of this.shippingDatas) {
        rs.push({
          title: shippingData.name,
          children: [
            {
              title: 'Delivery time($)',
              dataIndex: 'deliveryTime' + shippingData.id,
              key: 'deliveryTime' + shippingData.id,
              scopedSlots: { customRender: 'name' }
            },
            {
              title: 'Shipping fee($)',
              dataIndex: 'shippingFee' + shippingData.id,
              key: 'shippingFee' + shippingData.id,
              scopedSlots: { customRender: 'name' }
            }
          ]
        })
      }
      return rs
    },
    calculateShippingDatas() {
      if (!this.shippingDatas || this.shippingDatas.length === 0) {
        return []
      }
      var rs = []
      var weight = this.totalWeight
      if (weight) {
        weight = weight / 1000
      }
      for (const countryGroup of this.countryGroups) {
        var r = {}
        r.key = nanoid()
        r.shippingTo = countryGroup.name
        if (weight) {
          for (const shippingData of this.shippingDatas) {
            var logisticsMethodConfig = getLogisticsMethodConfigByCountryGroup(shippingData, countryGroup.id)
            if (logisticsMethodConfig) {
              var price = calculateLogisticsMethodPrice(weight, logisticsMethodConfig, shippingData)
              if (price) {
                r[`deliveryTime${shippingData.id}`] = logisticsMethodConfig.deliveryTime
                r[`shippingFee${shippingData.id}`] = MoneyFormat(price)
              }
            }
          }
        }
        rs.push(r)
      }
      return rs
    },
    currentSelectSku() {
      if (!this.selectSkuId) {
        return null
      }
      if (!this.skuList) {
        return null
      }
      if (!this.skuList.length) {
        return null
      }
      for (const skuListElement of this.skuList) {
        if (skuListElement.id === this.selectSkuId) {
          return skuListElement
        }
      }
      return null
    },
    totalWeight() {
      var sku = this.currentSelectSku
      if (!sku) {
        return null
      }
      if (!this.quantityValue) {
        return null
      }
      return sku.weight * this.quantityValue
    },
    totalWeightStr() {
      if (this.totalWeight) {
        return `${this.totalWeight}g`
      } else {
        return ''
      }
    }
  },
  created: function() {
  },
  mounted: function() {

  },
  methods: {
    handleClose() {
      this.visible = false
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    onSkuChange(v) {
      this.selectSkuId = v
    },
    async show() {
      if (this.skuList && this.skuList.length) {
        // this.selectSkuId = this.skuList[0].id
      }
      this.visible = true
      var that = this
      await utils.waitForEle(() => { return that.$refs.skuSelector })
      await this.$refs.skuSelector.selectFirst()
    }

  }
}
</script>

<style lang="less" scoped>
  @import '../../../assets/less/theme.less';
  .shippingPriceContainer{
    .shippingPriceTitle{
      font-size: 16px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      text-align: center;
      padding-top: 30px;
      // border-bottom: 1px dashed #eff2f5 !important;
      padding-bottom: 24px;
    }
    /deep/ .ant-select-selection__rendered{
      line-height: 1.875vw !important;
    }
    /deep/ .ant-select{
      height: 1.875vw !important;
    }
    .variantSelect /deep/ .ant-select-selection--single{
      width: 140px !important;
      height: 1.875vw !important;
      border: 1px solid #c2c2c2 !important;
      background-color: #fff !important;
      border-radius: 4px !important;
    }
    .ant-input-number{
      height: 1.875vw !important;
      border: 1px solid #c2c2c2 !important;
      background-color: #fff !important;
      border-radius: 4px !important;
    }
    /deep/ .ant-modal-body{
      height: 550px;
      overflow-y: auto;
      .commonBar(#d2d2d8, 8px, 8px);
    }
    /deep/ .ant-table-column-title{
      color: #000000 !important;
    }
    /deep/ .ant-table-thead > tr > th{
      background-color: #f2f2f2;
      // border-bottom: 1px dashed #eff2f5;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      // color: #999999;
    }
    /deep/ .ant-table-tbody > tr > td{
      background-color: transparent !important;
      border-bottom: 1px dashed #eff2f5 !important;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #000000;
    }
    /deep/ .ant-table-wrapper {
      border-bottom: none;
    }
  }
</style>
