<template>
  <div>
    <div class="diyDetailContainer ignore_view">
      <div class="navView">
        <a-breadcrumb>
          <a-breadcrumb-item>
            <a
              @click="callNewPage({
                path: '/'
              })"
            >
              Home
            </a>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <a
              v-if="type == enums.blankProductTypeEnum.DIY.value"
              @click="callNewPage({
                path: '/custom-gift'
              })"
            >
              Custom Gift
            </a>
            <a
              v-else
              @click="callNewPage({
                path: '/catalog'
              })"
            >
              Catalog
            </a>
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ blankProduct.blankProductInfo.model }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="productMessageView">
        <div class="productMessageViewLeft">
          <div class="smallImgView">
            <!-- class="pt20" -->
            <!-- <a-row :gutter="[10,20]" :style="{flexDirection: 'column'}" type="flex" justify="space-around">
              <a-col v-for="item in blankProduct.showImageList" :key="item" :span="6"> -->
            <div v-for="item in blankProduct.showImageList" :key="item" class="d-flex a-center j-center pb20">
              <img
                class="smallImg cursorPointer commonHover"
                :src="item"
                @click="currentSelectImg = item"
              >
            </div>
            <!-- </a-col>
            </a-row> -->
          </div>
          <div class="bigImg">
            <vue-photo-zoom-pro :high-url="currentSelectImg">
              <img
                class="bigImg"
                :src="currentSelectImg"
              >
            </vue-photo-zoom-pro>
          </div>
          <!-- <img
            class="bigImg"
            :src="currentSelectImg"
            :preview-src-list="blankProduct.showImageList"
          > -->
        </div>
        <div class="productMessageViewRight">
          <div class="productMessageRightTitleValue">{{ blankProduct.blankProductInfo.model }}</div>
          <div class="priceView">
            <span class="font16 ff-ss fw-400 fc-333">Price</span>
            <span v-if="priceAfterDiscount" class="font22 ff-ss fw-b priceValue">
              ${{ (priceAfterDiscount) | MoneyFormat }}
            </span>
            <span v-if="priceAfterDiscount" class="font16 ff-ss fw-b priceValue2">
              ${{ (price) | MoneyFormat }}
            </span>
            <span v-else class="font22 ff-ss fw-b priceValue">
              ${{ (price) | MoneyFormat }}
            </span>
            <div class="font16 ff-ss fw-400 fc-333 cursorPointer" style="text-decoration: underline;margin-left: auto;" @click="showPriceDetail">Price Details</div>
          </div>

          <div class="priceView wholeaslePrice">
            <span class="font16 ff-ss fw-400 fc-333">Shipping Cost</span><span class="font16 ff-ss fw-400 priceValue">${{ minLogisticsPrice | MoneyFormat }}</span>
            <div class="font16 ff-ss fw-400 fc-333 cursorPointer" style="text-decoration: underline;margin-left: auto;" @click="showShippingPriceDetail()">More Shipping</div>
          </div>
          <div class="priceView wholeaslePrice">
            <span class="font16 ff-ss fw-400 fc-333" style="color: transparent;user-select: none;">Shipping Cost</span>
            <span class="font12 ff-ss fw-400 pl20">{{ minLogisticsPriceInfo }}</span>
          </div>
          <div class="priceView wholeaslePrice" style="align-items: flex-start;">
            <span class="font16 ff-ss fw-400 fc-333">Specification</span><span class="font16 ff-ss fw-400 priceValue" style="color: transparent;user-select: none;" />
            <div class="d-flex a-center j-sb flex-1">
              <div>
                <div class="fc-bbb">Material</div>
                <div>{{ blankProduct.blankProductInfo.material }}</div>
              </div>
              <div>
                <div class="fc-bbb">Weight</div>
                <div>{{ weight }}</div>
              </div>
              <div>
                <div class="fc-bbb">Production time</div>
                <div>{{ blankProduct.blankProductInfo.productionTime }}</div>
              </div>
            </div>
          </div>
          <sku-selector
            class="current-selection--single "
            class-name=""
            :skus="blankProduct.skuInfos"
            place-holder-prefix="Select A"
            :option1-name="blankProduct.blankProductInfo.skuOption1"
            :option1-type="blankProduct.blankProductInfo.skuOption1Type"
            :option2-name="blankProduct.blankProductInfo.skuOption2"
            :option2-type="blankProduct.blankProductInfo.skuOption2Type"
            :option3-name="blankProduct.blankProductInfo.skuOption3"
            :option3-type="blankProduct.blankProductInfo.skuOption3Type"
            @skuChange="onSkuChange"
          >
            <template v-slot:optionName="slotProps">
              <div v-if="slotProps.index === 1" class="font18 ff-ss fw-400 fc-333 pb15 d-flex">
                {{ slotProps.optionName }}
                <!-- <div class="font16 ff-ss fw-400 fc-333" style="text-decoration: underline;margin-left: auto;cursor: pointer;" @click="showSizeDetail">Size Guide</div> -->
              </div>
              <span v-else class="font18 ff-ss">{{ slotProps.optionName }}</span>
            </template>
          </sku-selector>
          <div class="w-100">
            <a-button class="w-100 mt30" size="large" type="primary" @click="goDesign">Design Now</a-button>
            <a-button
              v-if="blankProduct.blankProductInfo.type === enums.blankProductTypeEnum.JET.value"
              class="w-100 mt30"
              @click="toUploadProduct"
            >Order Designed Mockups</a-button>
            <div v-if="CurrentShop">
              <a-button v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value) && blankProduct.blankProductInfo.type === enums.blankProductTypeEnum.DIY.value || (CurrentShop.shopType === enums.shopTypeEnum.ETSY.value) && blankProduct.blankProductInfo.type === enums.blankProductTypeEnum.DIY.value" :loading="syncToShopLoading" class="w-100 mt30" size="large" @click="syncToShop">Sync To My Shop</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="productDescription">
        <a-tabs v-model="activeName">
          <a-tab-pane key="Description" tab="Description">
            <div class="descriptionView" v-html="blankProduct.blankDescToC" />
          </a-tab-pane>
          <a-tab-pane key="Variants" tab="Variants">
            <div class="VariantsView">
              <a-table :columns="skuColumns" :data-source="skuDatas" :pagination="false" />
            </div>
          </a-tab-pane>
          <!--          <a-tab-pane key="Shipping Rates" tab="Shipping Rates">
            <div class="shippingRatesView">
              <a-table :columns="columnsShippingRates" :data-source="shippingDatas" :pagination="false" />
            </div>
          </a-tab-pane>-->
        </a-tabs>
      </div>

      <comment-list :product-id="productId" :show-margin-bottom="haveRecommendProducts" />

      <div v-if="haveRecommendProducts" class="productLikeView">
        <div class="productLikeTitleView">You may also like</div>
        <div class="productLikeContentView">
          <div v-for="(item) in recommendProducts" :key="item.id" class="productLikeContent-item">
            <product-item :product="item" />
          </div>
        </div>
      </div>
      <shipping-price-dialog ref="ShippingPriceDialog" :blank-product-info="blankProduct.blankProductInfo" :shipping-datas="blankProduct.logisticsMethodInfos" :country-groups="countryGroups" :sku-list="blankProduct.skuInfos" />
      <size-detail-dialog :metric="blankProduct.blankSpecMetric" :imperial="blankProduct.blankSpecImperial" :visible.sync="sizeVisible" />
      <price-detail-dialog :columns="skuColumns" :datas="skuDatas" :visible.sync="priceDetailVisible" />
    </div>
  </div>
</template>

<script>
import CommentList from './components/CommentList'
import ShippingPriceDialog from './components/ShippingPriceDialog'
import SizeDetailDialog from './components/SizeDetailDialog'
import PriceDetailDialog from './components/PriceDetailDialog'
import ProductItem from '@/components/product_item'
import { productDetail, blankProductPage, addDiyDesignProduct } from '@/api/product'
import SkuSelector from '@/components/sku_selector'
import skuOptionTypeEnum from '@/enums/blank_product/skuOptionTypeEnum'
import { mapGetters, mapState } from 'vuex'
import { MoneyFormat, RateFormat } from '@/filters'
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
import shopTypeEnum from '@/enums/shopTypeEnum'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum'
import { getDefaultLogisticsMethodConfig, calculateLogisticsMethodPrice } from './common'
import { countryGroupsApi } from '@/api/public'
export default {
  name: 'DiyDetail',
  components: {
    ShippingPriceDialog,
    SizeDetailDialog,
    PriceDetailDialog,
    SkuSelector,
    ProductItem,
    vuePhotoZoomPro,
    CommentList
  },
  props: {
    productId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      columnsShippingRates: [{
        title: 'Shipping to',
        dataIndex: 'enName',
        key: 'enName'
      }, {
        title: 'Stand Shipping Days',
        dataIndex: 'standardTransportDay',
        key: 'standardTransportDay'
      }, {
        title: 'Stand Shipping($)',
        dataIndex: 'standardFirstPrice',
        key: 'standardFirstPrice'
      }, {
        title: 'standAdditionalItems($)',
        dataIndex: 'standardNextPrice',
        key: 'standardNextPrice'
      }, {
        title: 'fastShippingDays',
        dataIndex: 'expressTransportDay',
        key: 'expressTransportDay'
      }, {
        title: 'fastShipping($)',
        dataIndex: 'expressFirstPrice',
        key: 'expressFirstPrice'
      }, {
        title: 'Fast Additional item($)',
        dataIndex: 'expressNextPrice',
        key: 'expressNextPrice'
      }],
      currentSelectImg: null,
      activeName: 'Description',
      sizeVisible: false, // 尺寸
      priceDetailVisible: false, // 价格
      blankProduct: {
        id: null,
        blankProductInfo: {},
        showImageList: [],
        skuInfos: [],
        skuMaps: [],
        skuTrees: [],
        shippingRateInfos: [],
        logisticsMethodInfos: [],
        detailImageList: [],
        blankDescToC: null,
        blankSpecMetric: null,
        blankSpecImperial: null
      },
      enums: {
        skuOptionTypeEnum,
        shopTypeEnum,
        blankProductTypeEnum
      },
      recommendProducts: [],
      patternInfos: [],
      countryGroups: [],
      syncToShopLoading: false,
      selectedSkuId: null
    }
  },
  computed: {
    ...mapGetters('User', {
      isLogin: 'getIsLogin'
    }),
    ...mapState({
      CurrentShop: state => state.User.merchantInfo.currentShop
    }),
    skuColumns() {
      var rs = []
      if (this.blankProduct.blankProductInfo.skuOption1) {
        rs.push({
          title: this.blankProduct.blankProductInfo.skuOption1,
          dataIndex: 'option1Value',
          key: 'option1Value'
        })
      }
      if (this.blankProduct.blankProductInfo.skuOption2) {
        rs.push({
          title: this.blankProduct.blankProductInfo.skuOption2,
          dataIndex: 'option2Value',
          key: 'option2Value',
          align: 'center'
        })
      }
      if (this.blankProduct.blankProductInfo.skuOption3) {
        rs.push({
          title: this.blankProduct.blankProductInfo.skuOption3,
          dataIndex: 'option3Value',
          key: 'option3Value',
          align: 'center'
        })
      }
      rs.push({
        title: 'Weight',
        dataIndex: 'weightStr',
        key: 'weight',
        align: 'center'
      })
      rs.push({
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        align: 'center'
      })
      rs.push({
        title: 'Original price',
        dataIndex: 'originalPrice',
        key: 'originalPrice',
        align: 'center'
      })
      rs.push({
        title: 'Price after discount',
        dataIndex: 'priceAfterDiscount',
        key: 'priceAfterDiscount',
        align: 'right'
      })
      return rs
    },
    skuDatas() {
      if (!this.blankProduct.skuInfos || this.blankProduct.skuInfos.length === 0) {
        return []
      }
      var rs = []
      for (const sku of this.blankProduct.skuInfos) {
        var r = {}
        r.key = sku.id
        if (this.blankProduct.blankProductInfo.skuOption1) {
          var option1 = JSON.parse(sku.option1)
          r.option1Value = option1.text
        }
        if (this.blankProduct.blankProductInfo.skuOption2) {
          var option2 = JSON.parse(sku.option2)
          r.option2Value = option2.text
        }
        if (this.blankProduct.blankProductInfo.skuOption3) {
          var option3 = JSON.parse(sku.option3)
          r.option3Value = option3.text
        }
        var discount = this.blankProduct.blankProductInfo.favorableDiscount || 0
        r.discount = RateFormat(discount) + '%'
        r.originalPrice = MoneyFormat(sku.price)
        r.weight = sku.weight
        if (r.weight) {
          r.weightStr = `${r.weight}g`
        } else {
          r.weightStr = '-'
        }
        r.priceAfterDiscount = MoneyFormat(sku.price * (1 - discount))
        rs.push(r)
      }
      return rs
    },
    shippingDatas() {
      if (!this.blankProduct.shippingRateInfos || this.blankProduct.shippingRateInfos.length === 0) {
        return []
      }
      var rs = []
      for (const shippingRateInfo of this.blankProduct.shippingRateInfos) {
        var r = Object.assign({}, shippingRateInfo)
        r.key = r.id
        r.standardFirstPrice = MoneyFormat(r.standardFirstPrice, 'free')
        r.standardNextPrice = MoneyFormat(r.standardNextPrice, 'free')
        r.expressFirstPrice = MoneyFormat(r.expressFirstPrice, 'free')
        r.expressNextPrice = MoneyFormat(r.expressNextPrice, 'free')
        rs.push(r)
      }
      return rs
    },
    weight() {
      if (!this.blankProduct.blankProductInfo.minWeight) {
        return ''
      }
      if (this.blankProduct.blankProductInfo.minWeight === this.blankProduct.blankProductInfo.maxWeight) {
        return `${this.blankProduct.blankProductInfo.minWeight}g`
      }
      return `${this.blankProduct.blankProductInfo.minWeight}-${this.blankProduct.blankProductInfo.maxWeight}g`
    },
    minLogisticsPrice() {
      if (!this.blankProduct.logisticsMethodInfos || this.blankProduct.logisticsMethodInfos.length === 0) {
        return null
      }
      if (!this.blankProduct.blankProductInfo.minWeight) {
        return null
      }
      var defaultLogisticsMethodConfig = getDefaultLogisticsMethodConfig(this.blankProduct.logisticsMethodInfos[0])
      var price = calculateLogisticsMethodPrice(this.blankProduct.blankProductInfo.minWeight / 1000, defaultLogisticsMethodConfig, this.blankProduct.logisticsMethodInfos[0])
      return price
    },
    minLogisticsPriceInfo() {
      if (!this.blankProduct.logisticsMethodInfos || this.blankProduct.logisticsMethodInfos.length === 0) {
        return null
      }
      if (!this.blankProduct.blankProductInfo.minWeight) {
        return null
      }
      var defaultLogisticsMethodConfig = getDefaultLogisticsMethodConfig(this.blankProduct.logisticsMethodInfos[0])
      return `Delivery to US,${this.blankProduct.logisticsMethodInfos[0].name}(Shipping time ${defaultLogisticsMethodConfig.deliveryTime})`
    },
    haveRecommendProducts() {
      if (this.recommendProducts && this.recommendProducts.length) {
        return true
      }
      return false
    },
    price() {
      if (!this.selectedSku) {
        return this.blankProduct.blankProductInfo.minSkuPrice
      }
      return this.selectedSku.originalPrice
    },
    priceAfterDiscount() {
      if (!this.selectedSku) {
        return this.blankProduct.blankProductInfo.favorablePrice
      }
      if (this.selectedSku.priceAfterDiscount !== this.selectedSku.originalPrice) {
        return this.selectedSku.priceAfterDiscount
      }
      return null
    },
    selectedSku() {
      if (!this.selectedSkuId) {
        return null
      }
      if (this.skuDatas && this.skuDatas.length) {
        for (const skuData of this.skuDatas) {
          if (skuData.key === this.selectedSkuId) {
            return skuData
          }
        }
      }
      return null
    }
  },
  mounted: function() {
    this.ssrCountSet(3)
    this.getProductDetail().finally(() => {
      this.ssrCountDown()
    })
    this.getRecommendProducts().finally(() => {
      this.ssrCountDown()
    })
    this.loadCountryGroups().finally(() => {
      this.ssrCountDown()
    })
  },
  methods: {
    async getProductDetail() {
      this.id = this.productId
      const { data } = await productDetail({
        id: this.id,
        blankProductInfo: true,
        skuInfos: true,
        skuMap: true,
        shippingRateInfos: true,
        showImageList: true,
        blankContent: true,
        blankDescToC: true,
        skuTree: true,
        blankSpecMetric: true,
        blankSpecImperial: true,
        blankPattern: true,
        shippingMethodInfos: true
      })
      this.blankProduct.id = data.id
      this.blankProduct.blankProductInfo = data.blankProductInfo
      this.blankProduct.skuInfos = data.skuInfos
      this.blankProduct.skuMap = data.skuMap
      this.blankProduct.skuTree = data.skuTree
      this.blankProduct.shippingRateInfos = data.shippingRateInfos
      this.blankProduct.logisticsMethodInfos = data.logisticsMethodInfos || []
      this.blankProduct.showImageList = data.showImageList
      this.blankProduct.blankDescToC = data.blankDescToC
      this.blankProduct.blankSpecMetric = data.blankSpecMetric
      this.blankProduct.blankSpecImperial = data.blankSpecImperial
      data.patternInfos.forEach(item => {
        item.knifeInfos.forEach(item2 => {
          var param = {}
          param.id = item2.id
          param.url = item2.knifePlateImageUrl
          this.patternInfos.push(param)
        })
      })

      this.currentSelectImg = this.blankProduct.showImageList[0]
    },
    async getRecommendProducts() {
      var postData = {}
      postData.current = 1
      postData.size = 100
      postData.recommendFromProductId = this.productId
      var response = await blankProductPage(postData)
      if (response.code === 200) {
        this.recommendProducts = response.data.records || []
      }
    },
    async loadCountryGroups() {
      var response = await countryGroupsApi({})
      if (response.code === 200) {
        this.countryGroups = response.data.records || []
      }
    },
    goDesign() {
      if (!this.isLogin) {
        this.callNewPage({
          path: `/login`,
          query: {
            redirect: this.$route.path
          }
        })
        return
      }
      console.log('this.blankProduct...', this.blankProduct)
      if (this.$route.params.type === '5') {
        this.callNewPage({
          path: '/Trending',
          query: {
            id: this.blankProduct.blankProductInfo.sdsProductId,
            productId: this.id,
            patternInfos: JSON.stringify(this.patternInfos)
          }
        })
      } else if (this.blankProduct.blankProductInfo.type === this.enums.blankProductTypeEnum.DIY.value) {
        this.callNewPage(`/diy_design/${this.productId}`)
      } else {
        // this.callNewPage({
        //   path: '/custom',
        //   query: {
        //     id: this.id,
        //     patternInfos: JSON.stringify(this.patternInfos)
        //   }
        // })
        this.callNewPage({
          path: '/diyCustom',
          query: {
            id: this.id
          }
        })
      }
    },
    toUploadProduct() {
      this.callNewPage(`/upload_product/${this.productId}`)
    },
    async syncToShop() {
      this.syncToShopLoading = true
      try {
        var postData = {}
        postData.blankProductId = this.productId
        postData.note = ''
        postData.noteEmail = ''
        // 图像框信息
        postData.imageBoxInfoList = []
        // 输入框信息
        postData.inputBoxInfoList = []

        var res = await addDiyDesignProduct(postData, true)
        if (res.code === 200) {
          this.callNewPage({
            path: '/mockups',
            query: {
              customProductId: res.data,
              productId: this.productId
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      } finally {
        this.syncToShopLoading = false
      }
    },
    showShippingPriceDetail(val) {
      this.$refs.ShippingPriceDialog.show()
    },
    showSizeDetail(val) {
      this.sizeVisible = true
    },
    showPriceDetail(val) {
      this.priceDetailVisible = true
    },
    updateSizeVisible(val) {
      this.sizeVisible = val
    },
    updatePriceDetailVisible(val) {
      this.priceDetailVisible = val
    },
    onSkuChange(v) {
      this.selectedSkuId = v
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/less/theme.less";
// .current-selection--single /deep/ .ant-select-selection--single{
//   height: 39px !important;
//   line-height: 39px !important;
//   background-color: #ffffff !important;
//   color: #5e6278;
//   border: 1px solid #e4e6ef !important;
//   border-radius: 8px;
// }
// .ant-select-open /deep/ .ant-select-selection--single {
//   border: 1px solid #b5b5c3 !important;
// }
.ant-btn:not(.ant-btn-primary){
  background-color: #eff8ff !important;
  border-color: #009EF7 !important;
  color: #009EF7 !important;
  opacity: 0.8;
  &:hover{
    background-color: #eff8ff !important;
    border-color: #009EF7 !important;
    opacity: 1;
  }
  &:disabled{
    background-color: #eff8ff !important;
    border-color: #009EF7 !important;
    color: #999999;
    opacity: 0.5;
  }
  &:focus{
    background-color: #eff8ff !important;
    color: #7e8299 !important;
    box-shadow: none;
    opacity: 1;
  }
  &:active{
    background-color: #eff8ff !important;
    border-color: #0095f8 !important;
    opacity: 1;
  }
}
.diyDetailContainer {
  width:@pageWidth;
  margin: 0 auto;
  // overflow-y: auto;
  background-color: #f8f8f8;
  .fw-b{font-weight: bold;}
  .fs-14{font-size: 14px;}
  .ff-ss{font-family: Segoe UI-Regular, Segoe UI;}
  .fw-400{font-weight: 400;}
  .fc-333{color: #333333;}
  .fc-bbb{color: #BBBBBB;}
  .navView {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .productMessageView {
    width: 100%;
    // height: 80%;
    display: flex;
    .productMessageViewLeft{
      width: 50%;
      display: flex;
      justify-content: space-between;
      .smallImgView{
        max-height: 540px;
        overflow-y: auto;
        padding-right: 10px;
        .commonBar(#d2d2d8, 8px, 8px);
      }
      .bigImg{
        width: 540px;
        height: 540px;
        border-radius: 20px;
      }
      .smallImg{
        width: 120px;
        height: 120px;
        border-radius: 5px;
      }
    }
    .productMessageViewRight{
      width: 50%;
      background-color: transparent;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      .productMessageRightTitle{
        font-size: 14px;
        font-family: Segoe UI-Regular, Segoe UI;
        font-weight: 400;
        color: #999999;
      }
      .productMessageRightTitleValue{
        font-size: 20px;
        font-family: Segoe UI-Bold, Segoe UI;
        font-weight: bold;
        color: #333333;
        padding-top: 14px;
      }
      .priceView{
        width: 100%;
        padding-top: 40px;
        display: flex;
        align-items: center;
        .priceValue{
          color: #009EF7;
          padding-left: 20px;
        }
        .priceValue2{
          color: #000000;
          text-decoration: line-through;
          padding-left: 20px;
        }
      }
      .wholeaslePrice{
        padding-top: 18px !important;
      }
      .sliderView{
        padding-top: 30px;
      }
    }
  }
  .productDescription {
    margin-top: 35px;
    width: 100%;
    // height: 16%;
    /deep/ .ant-tabs-bar{
      border-bottom: 0px !important;
    }
    .descriptionView{
      padding: 24px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-radius: 12px;
    }
    .VariantsView{
      padding: 24px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-radius: 12px;
    }
    .shippingRatesView{
      padding: 24px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-radius: 12px;
    }
  }

  .productLikeView {
    width: 100%;
    // height: 50%;
    .productLikeTitleView{
      width: 100%;
      margin-top: 80px;
      margin-bottom: 40px;
      font-size: 20px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .productLikeContentView{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      .productLikeContent-item{
        // width: 289px;
        // height: 455px;
        // background-color: #FFFFFF;
        border-radius: 10px;
        // padding: 14px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
        text-align: center;
        /deep/ img{
          border-radius: 8px !important;
          width: 307px !important;
          height: 320px !important;
        }
        /deep/ .portraitbox{
          border-radius: 8px !important;
          width: 307px !important;
          height: 320px !important;
        }
        /deep/ .commodityContainer{
          border-radius: 12px;
        }
        .imgView{
          width: 307px;
          // width: 100%;
          height: 320px;
          background: #C4C4C4;
          position: relative;
          border-radius: 8px;
          img{
            border-radius: 8px;
            width: 307px;
            height: 320px;
          }
        }
        .product-title{
          padding-top: 14px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .bestseller{
          padding-top: 6px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #BBBBBB;
        }
        .price{
          padding-top: 20px;
          font-size: 20px;
          font-family: SegoeUI-Bold-, SegoeUI-Bold;
          font-weight: normal;
          color: #009EF7;
        }
      }
    }
  }

/deep/ .ant-table-thead > tr > th{
  background-color: transparent;
  border-bottom: 1px solid #eeeeee;
  font-size: 14px;
  font-family: Segoe UI-Regular, Segoe UI;
  font-weight: 400;
  color: #999999;
}
/deep/ .ant-table-tbody > tr > td{
  background-color: transparent !important;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 14px;
  font-family: Segoe UI-Regular, Segoe UI;
  font-weight: 400;
  color: #333333;
}
/deep/ .ant-table-tbody > tr:nth-last-child(1) > td{
  border-bottom: none !important;
}
/deep/ .ant-table-wrapper {
  border-bottom: none;
}
}
</style>
