import { apiAxios } from '@/utils/request'

/** *
 * 评论列表
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const commentList = (data) => {
  return apiAxios(
    'post',
    '/shopapi/blankProductComment/list',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

/** *
 * 添加评论
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const addComment = (data) => {
  return apiAxios(
    'post',
    '/shopapi/blankProductComment/add',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

/** *
 * 点赞评论
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const likeComment = (data) => {
  return apiAxios(
    'post',
    '/shopapi/blankProductComment/like',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

/** *
 * 取消点赞评论
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const unlikeComment = (data) => {
  return apiAxios(
    'post',
    '/shopapi/blankProductComment/unlike',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
