<template>
  <div class="emptyReviewsContaner">
    <div><img :src="logo" class="logoView" alt="logo"></div>
    <div class="content">There are currently no reviews for this product. Do you want to be the first to review this product？</div>
    <div><a-button class="btnView" size="large" type="primary" @click="toWriteReview">Write a review</a-button></div>
  </div>
</template>

<script>
import empty_logo from '@/assets/img/empty_reviews_logo.png'
export default {
  name: 'EmptyReviews',
  data: function() {
    return {
      logo: empty_logo
    }
  },
  methods: {
    toWriteReview() {
      this.$emit('writereview')
    }
  }
}
</script>

<style scoped lang="less">
.emptyReviewsContaner{
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 60px;
  .logoView{
    width: 115px;
    height: 95px;
  }
  .content{
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #BBBBBB;
  }
  .btnView{
    width: 246px;
    height: 44px;
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 8px;
  }
}
</style>
