<template>
  <div class="reviewContentContainer">
    <div class="leftContent">
      <div class="allRateText">{{ starAverage }}</div>
      <div class="allRateDescription"><a-rate v-model="starAverage" disabled /><span class="rateText">{{ total }} Reviews</span></div>
      <div v-for="item in rateGraphItemList" :key="item.key" class="d-flex a-center rateGraphItem">
        <span class="pr_10">{{ item.startValue }}</span>
        <a-rate :value="item.rateValue" disabled :count="1" class="pr_10" />
        <a-progress :percent="item.progressPercent" :show-info="false" stroke-color="#333333" :stroke-width="4" />
        <span class="pl_10">{{ item.endValue }}</span>
      </div>
      <div><a-button class="rateBtnView" size="large" type="primary" @click="toWriteReview">Write a review</a-button></div>
      <div class="reviewsDescription">Leave a review for a product that how is your experience with this product?</div>
    </div>
    <div class="rightContent">
      <reviews-list :product-id="productId" :comments="comments" :total="total" @pagechange="onPageChange" />
    </div>
  </div>
</template>

<script>
import ReviewsList from './ReviewsList'
export default {
  name: 'ReviewsContent',
  components: {
    ReviewsList
  },
  props: {
    productId: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    },
    star1Num: {
      type: Number,
      default: 0
    },
    star2Num: {
      type: Number,
      default: 0
    },
    star3Num: {
      type: Number,
      default: 0
    },
    star4Num: {
      type: Number,
      default: 0
    },
    star5Num: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
    }
  },
  computed: {
    starAverage() {
      var totalStar = this.star1Num + this.star2Num * 2 + this.star3Num * 3 + this.star4Num * 4 + this.star5Num * 5
      if (!totalStar) {
        return 0
      }
      return (totalStar / this.total).toFixed(1)
    },
    totalStarNum() {
      return this.star1Num + this.star2Num + this.star3Num + this.star4Num + this.star5Num
    },
    rateGraphItemList() {
      var totalStarNum = this.totalStarNum
      var rs = []
      var star5Item = { key: 0, startValue: 5, endValue: this.star5Num, rateValue: 1, progressPercent: totalStarNum === 0 ? 0 : (this.star5Num / totalStarNum * 100).toFixed(1) }
      var star4Item = { key: 1, startValue: 4, endValue: this.star4Num, rateValue: 1, progressPercent: totalStarNum === 0 ? 0 : (this.star4Num / totalStarNum * 100).toFixed(1) }
      var star3Item = { key: 2, startValue: 3, endValue: this.star3Num, rateValue: 1, progressPercent: totalStarNum === 0 ? 0 : (this.star3Num / totalStarNum * 100).toFixed(1) }
      var star2Item = { key: 3, startValue: 2, endValue: this.star2Num, rateValue: 1, progressPercent: totalStarNum === 0 ? 0 : (this.star2Num / totalStarNum * 100).toFixed(1) }
      var star1Item = { key: 4, startValue: 1, endValue: this.star1Num, rateValue: 1, progressPercent: totalStarNum === 0 ? 0 : (this.star1Num / totalStarNum * 100).toFixed(1) }
      rs.push(star5Item)
      rs.push(star4Item)
      rs.push(star3Item)
      rs.push(star2Item)
      rs.push(star1Item)
      return rs
    }
  },
  methods: {
    toWriteReview() {
      this.$emit('writereview')
    },
    onPageChange(v) {
      this.$emit('pagechange', v)
    }
  }
}
</script>

<style lang="less" scoped>
.reviewContentContainer{
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  .pl_10{
    padding-left: 10px;
  }
  .pr_10{
    padding-right: 10px;
  }
  .leftContent{
    width: 246px;
    .allRateText{
      font-size: 24px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      padding-bottom: 26px;
    }
    .allRateDescription{
      padding-bottom: 20px;
      .rateText{
        padding-left: 24px;
        font-size: 16px;
        font-family: Segoe UI-Regular, Segoe UI;
        font-weight: 400;
        color: #333333;
      }
    }
    .rateGraphItem{
      width: 246px;
    }
    .rateBtnView{
      width: 246px;
      height: 44px;
      border-radius: 8px;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 24px;
      margin-bottom: 10px;
    }
    .reviewsDescription{
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #BBBBBB;
    }
  }
  .rightContent{
    padding: 56px 22px 20px 80px;
    flex: 1;
  }
}
</style>
