<template>
  <div>
    <a-modal
      class="priceDetailContainer"
      :visible.sync="visible"
      centered
      width="40%"
      :body-style="{width: '100%'}"
      @cancel="handleClose"
    >
      <div class="priceDetailTitle">Price Detail</div>
      <div>
        <a-table
          :columns="columns"
          :data-source="datas"
          :pagination="false"
          :showHeader="false"
        />
      </div>

      <span slot="footer">
        <a-button @click="handleClose">cancel</a-button>
        <a-button type="primary" @click="handleClose">confirm</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script >
export default {
  name: 'PriceDetailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    datas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', !this.visible)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/less/theme.less';
body{
  width: 100% !important;
}
.priceDetailContainer{
  .priceDetailTitle{
    font-size: 16px;
    font-family: Segoe UI-Bold, Segoe UI;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding-top: 30px;
    border-bottom: 1px dashed #eff2f5 !important;
    padding-bottom: 24px;
  }
/deep/ .ant-modal-body{
  height: 550px;
  overflow-y: auto;
  .commonBar(#d2d2d8, 8px, 8px);
}
/deep/ .ant-table-thead > tr > th{
  background-color: transparent;
  border-bottom: 1px dashed #eff2f5;
  font-size: 14px;
  font-family: Segoe UI-Regular, Segoe UI;
  font-weight: 400;
  color: #999999;
}
/deep/ .ant-table-tbody > tr > td{
  background-color: transparent !important;
  border-bottom: 1px dashed #eff2f5 !important;
  font-size: 14px;
  font-family: Segoe UI-Regular, Segoe UI;
  font-weight: 400;
  color: #000000;
}
/deep/ .ant-table-wrapper {
  border-bottom: none;
}
}
</style>
