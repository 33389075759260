<template>
  <div class="writeReviewsContainer">
    <div class="font_18">
      <a-icon type="arrow-left" @click="showWriteReviews" />
    </div>
    <div class="pt_24">
      Write a review
    </div>
    <div class="pt_24">
      Rating
    </div>
    <div>
      <a-rate v-model="rateValue" />
    </div>
    <div class="pt_24">
      Leave your order experience?
    </div>
    <div class="pt_14">
      <a-textarea
        v-model="reviewsContent"
        class="reviewsContentView"
        placeholder="Write a message..."
        :auto-size="{ minRows: 3, maxRows: 8 }"
        :max-length="500"
      />
    </div>
    <div class="d-flex pt_24">
      <div class="addPhotosView">
        <div class="d-flex a-center">
          <span>images({{ imageUrls.length }}/{{ 6 }})</span>
          <a-upload
            accept=".jpg, .jpeg, .png"
            class="ml-auto cursorPointer"
            :disabled="imageUrls.length >= 6"
            :multiple="false"
            :show-upload-list="false"
            :custom-request="submitUpload"
          >
            <a-button :loading="uploadLoading" class="addPhotos" size="large" type="default">Add Photos</a-button>
          </a-upload>
        </div>
        <div class="pt_14 imgView">
          <!-- 图片集合 -->
          <div
            v-for="imageUrl in imageUrls"
            :key="imageUrl"
            class="imgItem"
          >
            <a-icon class="removeIcon" type="close-circle" theme="filled" @click="removeItem($event,imageUrl)" />
            <images
              class="portrait commontransition "
              :url="imageUrl"
              :width="120"
              :height="120"
              fit="cover"
            />
          </div>
        </div>
      </div>
      <a-button class="submitBtn" size="large" type="primary" @click="submitData">Submit</a-button>
    </div>
  </div>
</template>

<script>
import aliOss from '@/utils/aliOss'
import { fileSts } from '@/api/oss'
import images from '@/components/images'
import { addComment } from '@/api/comment'
export default {
  name: 'WriteReviews',
  components: {
    images
  },
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      rateValue: 0,
      reviewsContent: '',
      imageUrls: [],
      uploadLoading: false
    }
  },
  methods: {
    async submitData() {
      if (!this.reviewsContent) {
        this.$message.error('Please write a message')
        return
      }
      if (!this.rateValue) {
        this.$message.error('Please select a rating')
        return
      }
      var postData = {}
      postData.productId = this.productId
      postData.star = this.rateValue
      postData.photos = this.imageUrls || []
      postData.comment = this.reviewsContent
      await addComment(postData)
      this.$message.success('Thank you for your comment')
      this.$emit('done')
    },
    removeItem(e, item) {
      const index = this.imageUrls.findIndex(i => i === item)
      this.imageUrls.splice(index, 1)
    },
    showWriteReviews() {
      this.$emit('done')
    },
    async submitUpload(option) {
      this.uploadLoading = true
      const currentImg = option.file
      var oss = await aliOss.oss(fileSts)
      var ossObject = aliOss.getRandomObjectName(
        oss.ossObjectNamePrefix,
        oss.domain,
        currentImg.name
      )
      // 提交到阿里云
      try {
        await oss.client.multipartUpload(ossObject.objectName, currentImg, {
          headers: { 'Cache-Control': 'max-age=360000' }
        })
        console.log('upload image success', ossObject.objectUrl)
        var newImageUrls = []
        for (const imageUrl of this.imageUrls) {
          newImageUrls.push(imageUrl)
        }
        newImageUrls.push(ossObject.objectUrl)
        this.imageUrls = newImageUrls
        this.uploadLoading = false
      } catch (err) {
        console.log(err)
        this.uploadLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.writeReviewsContainer{
  .pt_24{
    padding-top: 24px;
  }
  .pt_14{
    padding-top: 14px;
  }
  .font_18{
    font-size: 18px;
  }
  .reviewsContentView{
    padding: 24px;
    border: 1px solid #DDDDDD !important;
    background-color: #fff !important;
    height: 260px !important;
  }
  .addPhotosView{
    flex: 1;
    .addPhotos{
      width: 112px;
      height: 44px;
      margin-left: auto;
      border-radius: 8px;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      color: #009EF7 !important;
      border: 1px solid #009EF7 !important;
      background-color: #EFF8FF !important;
      margin-right: 24px;
    }
    .imgView{
      display: flex;
      align-items: center;
      gap: 5px;
      .imgItem{
        width: 120px;
        height: 120px;
        position: relative;
        .removeIcon{
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
        }
        .commontransition{
          /deep/ img{
            width: 100%;
            height: 100%;
          }
        }
        .portrait {
          width: 120px;
          height: 120px;
          border-radius: 8px;
          object-fit: cover;
          display: block;
        }
      }
    }
  }
  .submitBtn{
    width: 92px;
    height: 44px;
    border-radius: 8px;
    font-size: 14px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #fff;
    border: 1px solid #009EF7;
  }
}
</style>
