<template>
  <component :is="detailComponent" v-if="isAlive" :product-id="id" :type="type" />
</template>

<script>
import podDetail from './product/productDetail'
import diyDetail from './diydetail/detail'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum'
export default {
  name: 'ProductDetail',
  components: {
    podDetail,
    diyDetail
  },
  props: {
    type: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isAlive: true
    }
  },
  computed: {
    detailComponent() {
      if (parseInt(this.type) === blankProductTypeEnum.DIY.value) {
        return diyDetail
      } else {
        return diyDetail
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isAlive = false
        this.$nextTick(async function() {
          this.isAlive = true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
