<template>
  <div class="reviewsListContainer">
    <a-list
      :bordered="false"
      :data-source="comments"
      :pagination="{
        size: 'small',
        total: total,
        pageSize: pageSize,
        current: pageIndex,
        onChange: onPaginationChange
      }"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <div class="itemContentView">
          <div class="itemTitle">
            <div class="itemName">{{ item.nickname }}</div>
            <div class="itemRate"><a-rate :value="item.star" disabled /></div>
            <div class="itemTime">{{ item.time }}</div>
          </div>
          <div class="itemContent"> {{ item.comment }} </div>
          <template v-if="item.photos && item.photos.length">
            <div class="itemImgs">
              <images
                v-for="(photo, index) in item.photos"
                :key="index"
                class="portrait commontransition "
                :url="photo"
                :width="120"
                :height="120"
                fit="cover"
              />
            </div>
          </template>
          <div class="itemLike">
            <span :style="{cursor: 'pointer'}" @click="likeItem(item)">
              <template v-if="!item.isLike">
                <a-icon type="like" />
              </template>
              <template v-else>
                <a-icon type="like" theme="filled" :style="{color: '#009EF7'}" />
              </template>
              <span class="pl_14">{{ item.likeNum }}</span>
            </span>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import empty_reviews_logo from '@/assets/img/empty_reviews_logo.png'
import images from '@/components/images'
import { likeComment, unlikeComment } from '@/api/comment'
export default {
  name: 'ReviewsList',
  components: {
    images
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    productId: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      data: [
        { key: 0, name: 'By Con******', rateValue: 5, content: `This is a great product and quality. 
        My clients love this.This is a great product and quality. My clients love this.This is a great product and quality.
        My clients love this.`, time: this.$moment(new Date()).format('DD MMM YYYY'), zanValue: 5 },
        { key: 1, name: 'By Con******', rateValue: 5, content: `This is a great product and quality. 
        My clients love this.This is a great product and quality. My clients love this.This is a great product and quality.
        My clients love this.`, time: this.$moment(new Date()).format('DD MMM YYYY'), zanValue: 55 },
        { key: 2, name: 'By Con******', rateValue: 5, content: `This is a great product and quality. 
        My clients love this.This is a great product and quality. My clients love this.This is a great product and quality.
        My clients love this.`, time: this.$moment(new Date()).format('DD MMM YYYY'), zanValue: 55, imgView: [empty_reviews_logo, empty_reviews_logo] }
      ],
      currentPagination: {},
      pageIndex: 1,
      pageSize: 10
    }
  },
  methods: {
    async likeItem(item) {
      var postData = {}
      postData.commentId = item.id
      postData.productId = this.productId
      if (item.isLike) {
        // 取消点赞
        await unlikeComment(postData)
        item.isLike = false
        item.likeNum = item.likeNum - 1
      } else {
        // 点赞
        await likeComment(postData)
        item.isLike = true
        item.likeNum = item.likeNum + 1
      }
    },
    onPaginationChange(page, pageSize) {
      console.log(page, pageSize)
      this.pageIndex = page
      this.$emit('pagechange', { pageSize: pageSize, pageIndex: this.pageIndex })
    }
  }
}
</script>

<style lang="less" scoped >
  .reviewsListContainer{
    .ant-list-split .ant-list-item:last-child{
      border-bottom: 1px solid #e8e8e8 !important;
    }
    .pl_14{
      padding-left: 14px;
    }
    .itemContentView{
      flex: 1;
      .itemTitle{
        display: flex;
        align-items: center;
        .itemName{
          font-size: 16px;
          font-family: Segoe UI-Bold, Segoe UI;
          font-weight: bold;
          color: #333333;
        }
        .itemRate{
          padding-left: 40px;
        }
        .itemTime{
          margin-left: auto;
          font-size: 14px;
          font-family: Segoe UI-Regular, Segoe UI;
          font-weight: 400;
          color: #BBBBBB;
        }
      }
      .itemContent{
        max-width: 888px;
        font-size: 14px;
        font-family: Segoe UI-Regular, Segoe UI;
        font-weight: 400;
        color: #333333;
        padding-top: 24px;
        padding-bottom: 24px;
      }
      .itemImgs{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .itemLike{
        font-size: 16px;
      }
    }
    .commontransition{
      /deep/ img{
        width: 100%;
        height: 100%;
      }
    }
    .portrait {
      width: 120px;
      height: 120px;
      border-radius: 8px;
      object-fit: cover;
      display: block;
    }
  }
</style>
